<template>
  <div>
    <div v-if="show" class="notice-dialog">
      <div class="notice-dialog-wrapper">
        <div v-html="globalStore.notice"></div>
        <div class="close" @click="onClose">&times;</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import useGlobalStore from '@/store/global'

const globalStore = useGlobalStore()

// 弹窗公告不为空，且页面跳转超过4次
const show = computed(() => globalStore.notice && globalStore.routeJumpCount > 4)

const onClose = () => {
  globalStore.notice = ''
}
</script>

<style lang="scss">
.notice-dialog{
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.6);
  z-index: 9999;
  padding-bottom: 8em;
  .notice-dialog-wrapper{
    position: relative;
  }
  .close{
    position: absolute;
    font-size: 1.5em;
    width: 1.8em;
    height: 1.8em;
    border: solid 1px rgba(255,255,255,0.5);
    color: #fff;
    left: 50%;
    top: 100%;
    margin-top: 2em;
    margin-left: -.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
</style>