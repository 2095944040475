import { isAndroidApp } from './device'

export const sleep = (time) => new Promise(resolve => {
  setTimeout(() => {
    resolve()
  }, time)
})

export const preZero = (n) => {
  const str = n.toString()
  return str[1] ? str : `0${str}`
}

export function hoursFormat(seconds) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60
  return preZero(hours) + ':' + preZero(minutes) + ':' + preZero(remainingSeconds)
}

/**
 * 秒级（非毫秒）时间戳转换
 * @param {*} stamp 时间戳
 */
export const stampFormat = (date, format='YYYY-MM-DD') => {
  if (!date) return ''
  if (!(date instanceof Date)) date = new Date(parseInt(`${date}000`))
	const year = date.getFullYear()
	const month = preZero(date.getMonth()+1)
	const day = preZero(date.getDate())
  const hours = preZero(date.getHours())
  const minutes = preZero(date.getMinutes())
  const seconds = preZero(date.getSeconds())
	return format.replace('YYYY', year).replace('MM', month).replace('DD', day).replace('hh', hours).replace('mm', minutes).replace('ss', seconds)
}

 /**
 * 获取开始日期和结束日期
 * @param {number} days 0和1表示当天 -1昨天 -2前天 n表示n天之内
 * @returns 
 */
 export const getStartAndEndDate = (days=0, char='-') => {
  const now = new Date()
  if (days > 0) {
    const isToday = days == 1
    const start = new Date(now.getTime()-3600*24000*(days-1))
    const end_date = `${now.getFullYear()}${char}${preZero(now.getMonth()+1)}${char}${preZero(now.getDate())}`
    const start_date = `${start.getFullYear()}${char}${preZero(start.getMonth()+1)}${char}${preZero(start.getDate())}`
    return {start_date:isToday?end_date:start_date, end_date, isToday}
  } else {
    const beforeDate = new Date(now.getTime() + 3600*24000*days)
    const date = `${beforeDate.getFullYear()}${char}${preZero(beforeDate.getMonth()+1)}${char}${preZero(beforeDate.getDate())}`
    return {start_date:date, end_date:date, isToday:days==0, isYesterday:days==-1, isBeforeYesterday:days==-2}
  }
}

/**
 * 获取某周的开始日期和结束日期
 * @param {*} n 0表示本周 1表示上周 2表示上上周
 */
export const getWeekStartAndEndDate = (n=0) => {
  const weekStamp = n*7*3600*24*1000
  const date = new Date(Date.now() - weekStamp)
  // 设置日期为周一
  let monday = new Date(date.setDate(date.getDate() - date.getDay() + 1))
  // 设置日期为周日
  let sunday = new Date(date.setDate(date.getDate() - date.getDay() + 7))
  return {
    start_date: stampFormat(monday),
    end_date: stampFormat(sunday),
  }
}

/**
 * 获取某月的开始日期和结束日期
 * @param {*} n 0表示本月 1表示上月 2表示上上月
 */
export const getMonthStartAndEndDate = (n=0) => {
  const now = new Date()

  let firstDayOfMonth = new Date(now.getFullYear(), now.getMonth() - n, 1);
  let lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() - n + 1, 0);
  return {
    start_date: stampFormat(firstDayOfMonth),
    end_date: stampFormat(lastDayOfMonth),
  }
}

export const copy = (str) => {
  return new Promise((resolve, reject) => {
    // APP端复制
    if (isAndroidApp()) {
      if (cordova.plugins.clipboard) {
        cordova.plugins.clipboard.copy(str)
        resolve()
      } else {
        reject()
      }
    }
    // 浏览器端复制
    else if (navigator.clipboard) {
      navigator.clipboard.writeText(str).then(resolve).catch(reject)
    } else {
      const input = document.createElement('textarea')
      input.value = str
      input.style.cssText = 'position:fixed;opacity:0'
      document.body.append(input)
      input.select()
      document.execCommand('copy')
      input.remove()
      resolve()
    }
  })
}

// query对象转url格式
export const query2str = (query) => {
  const params = new URLSearchParams()
  for (const key in query) {
    params.append(key, query[key])
  }
  return params.toString()
}

export const numFormat = num => {
  if (num > 10000) return `${Math.round(num/1000)/10}w`
  if (num > 1000) return `${Math.round(num/100)/10}k`
  return num || ''
}

export const deepMerge = (obj1, obj2) => {
  let key;
  for (key in obj2) {
    // 如果target(也就是obj1[key])存在，且是对象的话再去调用deepMerge，否则就是obj1[key]里面没这个对象，需要与obj2[key]合并
    obj1[key] = obj1[key] && obj1[key].toString() === "[object Object]"
      ? deepMerge(obj1[key], obj2[key])
      : (obj1[key] = obj2[key]);
  }
  return obj1;
}

export const isIPv4 = (address) => {
  const ipv4Regex = /^(https?:\/\/)?(\d{1,3}\.){3}\d{1,3}(:\d+)?$/;
  return ipv4Regex.test(address);
}

export const isIPv6 = (address) => {
  const ipv6Regex = /^(https?:\/\/)?(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}(:\d+)?$/;
  return ipv6Regex.test(address);
}

// 提取二级域名（非www）
export const getSubDomain = domain => {
  if (isIPv4(domain)) return ''
  const matches = domain.match(/^https?:\/\/((.+?)\.)?.+?\..+/)
  const sub = matches ? matches[2] || '' : ''
  return sub === 'www' ? '' : sub
}

// 正则去掉二级域名
export const getRootDomain = domain => {
  return domain.replace(/^(https?:\/\/)(.+?\.)*(.+?\.[^\.]+)$/, `$1$3`)
}

// 域名附加二级域名
export const setSubDoamin = (domain, code) => {
  if (!code || !domain) return domain
  domain = domain.replace(/^(https?:\/\/)(.+?\.)?(.+?\..+)/, `$1${code}.$3`)
  return domain
}

// 获取随机域名
export const getRandomDomain = domains => {
  const len = domains?.length
  if (!len) return
  const randomIdx = Math.floor(Math.random()*len)
  let url = domains.slice(randomIdx, randomIdx+1)[0]
  return url
}

export default {
  sleep,
  preZero,
  hoursFormat,
  stampFormat,
  getStartAndEndDate,
  getWeekStartAndEndDate,
  getMonthStartAndEndDate,
  copy,
  query2str,
  numFormat,
}