import { defineStore } from 'pinia'
import { getToken } from '@/utils/token'
import API from '@/api'
import { showToast } from 'vant'
import { decryptU8arr, decodePicture } from '@/utils/picDecode'
import { getRandomDomain, getRootDomain } from '@/utils/common'

export default defineStore('global', {
  state: () => {
    return {
      firstRendered: false,
      routeJumpCount: 0, //路由页面跳转计数
      userInfo: {},
      setting: {
        safedomains: [], //安全组
        landingdomains: [], //留存组
        promotiondomains: [], //推广组
        transferdomains: [], //发布组
        advdomains: [], //广告组
        statistics: '', //默认统计代码
        statisticsVOMap: {}, //渠道：识别码+留存、推广统计代码
        imgdomain: 'https://mogo.ewcdn.com',
        // imgdomain: 'https://s3.dualstack.ap-east-1.amazonaws.com',
        playdomain: 'https://mogo.ewcdn.com',
        isGrouped: false,
        domainGroup: 0, //所属域名组，默认不属任何组。1发布 2留存 3推广
        staticdomain: ''
      },
      categories: [],
      marquee: '',
      notice: '',
      
      // PWA
      showPWAPop: false,
      lastPWACloseTime: 0,
    }
  },
  getters: {
    imgDomain: state => state.setting.imgdomain,
    isLogin: state => getToken() && state.userInfo.uid,
    randSafeDomain: state => getRandomDomain(state.setting.safedomains)?.replace(/^https:\/\//, '') || '',
    playdomain: state => state.setting.playdomain,
    staticDomain: state => state.setting.staticdomain ? state.setting.staticdomain + '/upload/mogo/assets' : 'https://mogo.ewcdn.com/upload/mogo/assets',
  },
  actions: {
    isOffline(msg){
      showToast(msg||'暂未开放')
    },
    // 清除路由页面跳转计数
    resetRouteJumpCount() {
      this.routeJumpCount = 0;
    },
    async getSetting(){
      const res = await API.GET_SETTING()
      if (res.errCode === 0) {
        this.setting = {
          ...this.setting,
          ...res.data,
        }
      }
    },
    // 退出登录清除用户信息
    resetUserinfo () {
      this.userInfo = {}
    },
    // 本机域名归组
    setGroup(domains, group){
      if (this.setting.isGrouped) return
      for (let domain of domains) {
        const origin = location.origin
        domain = domain?.trim()?.replace(/\/$/,'')
        if (origin == domain) {
          this.setting.domainGroup = group
          this.setting.isGrouped = true
          break
        } else if (group == 2) {
          const originRootDomain = getRootDomain(origin)
          const rootDomain = getRootDomain(domain)
          if (originRootDomain == rootDomain) {
            this.setting.domainGroup = group
            this.setting.isGrouped = true
            break
          }
        }
      }
    },
    async getCategoryTree(query={}) {
      const res = await API.GET_CATEGORIES(query)
      this.categories = res.data ? res.data.filter(item => item.name !== '短视频') : [];
      const base = [{ name: '精选', active: true }, { name: '热榜' }]
      // 尾部插入空值，方便滚动
      const empty = [{ name: ''}]
      this.categories.unshift(...base)
      this.categories.push(...empty)
    },
    async getNotice(){
      const res = await API.GET_NOTICE()
      this.marquee = res.data?.[0].content || ''
      this.notice = res.data?.[1].content || ''
    },

    /**
     * 视频片段私有解密
     * @param {Buffer} buffer 切片数据
     * @returns {Buffer} 需返回解密后的buffer数据
    */
    videoDecrypter: (buffer) => {
      return decryptU8arr(new Uint8Array(buffer)).buffer
    },

    /**
     * 图片私有解密
     * @param {String} url 未解密图片链接
     * @returns {Promise} 需返回解密后的promise.resolve(blobUrl)
    */
    pictureDecrypter: (url) => {
      return decodePicture({url}).catch(e => console.error(e))
    },
    
  }
})