import Collector from '@/utils/Collector'
import API from '@/api'
import { encrypt, decrypt } from '@/utils/encrypt'
import dial from '@/utils/dial'
import useGlobalStore from '@/store/global'

export default () => {
  const globalStore = useGlobalStore()

  // 待拨测域名
  let beDialDomains = [
    ...globalStore.setting.landingdomains,
    ...globalStore.setting.promotiondomains,
    ...globalStore.setting.transferdomains,
    ...globalStore.setting.safedomains,
  ]
  if (!beDialDomains.length) return

  // 保存已拨测域名（需携带拨测时间戳）
  let dialedDomains = []
  const saveDialed = () => {
    localStorage.setItem(`_dialedDomains`, encrypt(JSON.stringify({
      checkedTime: Date.now(),
      domains: dialedDomains
    })))
  }

  // 已拨测域名过滤（当天已拨测过的域名不再拨测）
  const filterDialedDomains = () => {
    const encode = localStorage.getItem('_dialedDomains')
    if (!encode) return
    try{
      const decode = JSON.parse(decrypt(encode))
      const today0hour = new Date().setHours(0, 0, 0, 0) // 凌晨零点时间戳
      if (decode.checkedTime > today0hour && decode.domains.length) {
        dialedDomains = decode.domains
        beDialDomains = beDialDomains.filter(domain => !decode.domains.includes(domain))
      }
    }catch(e){
      localStorage.removeItem('_dialedDomains')
      console.warn(e)
    }
  }
  filterDialedDomains()
  if (!beDialDomains.length) return

  // 拨测日志收集器，达到收集器阈值（默认50条记录）自动执行回调函数
  const collector = new Collector({}, logs => {
    // 返回 Promise 可以回收执行失败的日志，重新执行
    return API.SEND_DIAL_LOGS(logs)
  })

  // 每10秒拨测一个随机域名
  let timer, duration = 10000
  const onDial = () => {
    if (!beDialDomains.length) {
      timer && clearTimeout(timer)
      return
    }

    const randIdx = Math.floor(Math.random()*beDialDomains.length)
    const randDomain = beDialDomains.splice(randIdx, 1)[0]
    timer = setTimeout(async () => {
      try{
        await dial(randDomain).then(() => {
          collector.add({
            available: true,
            domain:randDomain,
          })
        }).catch(() => {
          collector.add({
            available: false,
            domain:randDomain,
          })
        })
      }finally{
        dialedDomains.push(randDomain)
        saveDialed()
        onDial()
      }
    }, duration)
  }
  onDial()
}